<template>
  <v-container className="keystone-tool-container">
    <stepper
      v-bind="stepperAttributes()"
      v-on="stepperEvent()"
    >
      <step-project-creation v-bind="stepAttributes1(1)"></step-project-creation>
      <step-creation-service-account v-bind="stepAttributes1(2)"></step-creation-service-account>
      <step-configure-bigquery v-bind="stepAttributes1(3)"></step-configure-bigquery>

      <step-first-init v-bind="stepAttributes1(4)"
                       @launch="launchFirstInit"
      ></step-first-init>

    </stepper>
  </v-container>
</template>

<script>

import { projectConfig } from '../../../mixins/projectConfig'
import StepCreationServiceAccount from './step/creation_service_account'
import StepConfigureBigquery from './step/configure_bigquery'
import StepFirstInit from './step/first_init'
import Stepper from './step/stepper'
import stepProjectCreation from './step/project_creation'

export default {
  name: 'projectConfigMeta',
  mixins: [projectConfig],
  components: {
    StepCreationServiceAccount,
    StepConfigureBigquery,
    StepFirstInit,
    Stepper,
    stepProjectCreation
  },
  data: function () {
    return {
      dsp: 'meta',
      loading: {
        serviceAccountCredentials: false,
        serviceAccountToken: false
      },
      saCredentials: {
        clientId: '',
        clientSecret: ''
      },
      saSettingsCreation: {
        url_creation: '',
        url_callback: '',
        account_name: ''
      },
      saAuthUrl: '',
      downloadMethod: {
        type: '',
        bucketName: '',
        prefixName: ''
      },
      stepSettings: [
        { stepName: 'project_creation' },
        { stepName: 'creation_service_account' },
        { stepName: 'config_bigquery' },
        { stepName: 'first_init', informationRequired: true }
      ],
      timeout: {
        reloadUntil: null
      }
    }
  },
  methods: {
    launchFirstInit: function () {
      this.doStep(this.getStepId('first_init'))
    }
  },
  watch: {}
}
</script>

<style scoped>
</style>
