<template>
  <v-card>
    <TopKeystoneToolbar
      entity-type="member"
      v-model="search"
      :open-new="openNewMember"
      disabled-new
      v-on:refresh="refresh"
      hide-new
    >
    </TopKeystoneToolbar>

    <MemberForm
      :entity-id="currentMemberId"
      :is-edit-form="isEditForm"
      v-model="dialogForm"
      :default-entity="defaultEntity"
      v-on:refresh="refresh"
      :key="dialogForm"
    >
    </MemberForm>

    <div class="entity-view-div">
      <v-data-table
        :headers-length="5"
        :loading="$store.getters.getLoadingMembers"
        :items="members"
        :headers="headers"
        :options.sync="pagination"
        :server-items-length="totalItems"
        @update:options="callApi"
        :footer-props="{
          itemsPerPageOptions: rowsPerPageItems
        }"
      >
        <template v-slot:item="props">
          <tr>
            <td>
              {{ props.item.member.id}}
            </td>
            <td>
              <v-img
                v-if="props.item && props.item.member && props.item.member.dsp"
                :src="require('../../assets/' + props.item.member.dsp + '.png')"
                aspect-ratio="1"
                heigth="1em"
                width="1em"
                avatar
                class="dsp-img"
                :title="props.item.member.dsp"
                :contain="true"
              >
              </v-img>
            </td>
            <td>
              <strong>
                {{ props.item.member.name }}
              </strong>
            </td>
            <td>
              <v-row wrap>
                <v-col cols="5">
                  <span style="font-style: italic">{{ props.item.member.external_id }}</span>
                </v-col>
                <v-col cols="2" v-if="props.item.member.id && props.item.member.external_id && !props.item.member.is_set">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-icon style="font-size: 15px" color="red">
                          warning
                        </v-icon>
                      </div>
                    </template>
                    <span>
                    This member is not set !
                  </span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </td>
            <td>
              <v-btn icon>
                <v-icon @click="openEditMember(props.item.member.id)">
                  edit
                </v-icon>
              </v-btn>

              <v-btn icon @click="openAction(props.item.member)">
                <v-icon>
                  offline_bolt
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>

      <v-dialog eager v-model="dialogAction" persistent width="500px">
        <KeystoneActionV2
          :is-open="dialogAction"
          :member="actionMember"
          v-on:close="closeAction"
        >
        </KeystoneActionV2>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import store from '../../../store'
import { keystoneV2 } from '../../../store/modules/keystoneV2'
import TopKeystoneToolbar from '@/components/KeystoneV2/TopKeystoneToolbar'
import MemberForm from '@/components/KeystoneV2/Form/MemberForm'
import { openFormOptionMixin } from '@/mixins/openFormOptionMixin'
import KeystoneActionV2 from '@/components/KeystoneV2/tools/KeystoneActionV2'
import MemberModel from '@/models/Keystone_v2/MemberModel'
import { keystoneTabViewMixin } from '@/mixins/keystoneTabViewMixin'
import { ROW_PER_PAGE_ITEM } from '@/components/KeystoneV2/commonConstantKeystone'
import { getEntityApiPayload } from '@/components/KeystoneV2/payloadHelperKeystone'
import _ from 'lodash'
// load the store module dynamically only when needed
if (!store.state.keystoneV2) store.registerModule('keystoneV2', keystoneV2)

export default {
  name: 'MemberView.vue',
  components: {
    MemberForm,
    TopKeystoneToolbar,
    KeystoneActionV2
  },
  mixins: [openFormOptionMixin, keystoneTabViewMixin],
  data: function () {
    return {
      dialogForm: false,
      search: '',
      headers: [
        { text: 'Internal ID', value: 'member.id', width: '15px', sortable: false },
        { text: 'Dsp', value: 'member.dsp' },
        { text: 'Member name', value: 'member.name' },
        { text: 'External id', value: 'member.external_id' },
        { text: 'Action', value: '', sortable: false }
      ],
      isEditForm: false,
      currentMemberId: null,
      defaultEntity: null,
      entityType: 'member',
      dialogAction: false,
      actionMember: null,
      rowsPerPageItems: ROW_PER_PAGE_ITEM,
      pagination: {}
    }
  },
  async mounted () {
    await this.callApiIfRightTab()
  },
  methods: {
    async callApi () {
      const payload = getEntityApiPayload(this.search, this.pagination, 'memberId')
      await this.$store.dispatch('getApiKeystoneMembers', payload)
    },
    async refresh () {
      if (this.$store.getters.getLoadingMembers) {
        return
      }
      await this.callApi()
    },
    openNewMember () {
      console.warn('Not implemented')
    },
    openEditMember (memberId) {
      this.isEditForm = true
      this.dialogForm = true
      this.currentMemberId = memberId
    },
    openAction (member) {
      this.actionMember = new MemberModel(member)
      this.dialogAction = true
    },
    closeAction () {
      this.dialogAction = false
      this.actionMember = null
    },
    /**
     * called by open form mixin
     */
    openNew () {
      this.openNewMember()
    },
    /**
     * called by open form mixin
     * @param memberId
     */
    openEdit (memberId) {
      this.openEditMember(memberId)
    }
  },
  computed: {
    members: function () {
      return this.$store.getters.getMembers
    },
    totalItems: function () {
      return this.$store.getters.getTotalCountKeystoneMembers
    }
  },
  watch: {
    '$store.getters.getTabSearchMembers': {
      immediate: false,
      deep: true,
      handler: async function (newValue) {
        this.pagination.page = 1
        await this.callApi()
      }
    },
    search: _.debounce(function () {
      this.callApi()
    }, 600)
  }
}
</script>

<style scoped>

</style>
