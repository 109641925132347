
import Vue from 'vue'
import { DSPSettingsModel } from '../../models/Keystone/DSPSettingsModel'
import MemberSettingModel from '../../models/Keystone_v2/MemberSettingModel'
import NewFeatureReferenceModel from '../../models/Keystone_v2/NewFeatureReferenceModel'
import NewFeaturesComponent from './NewFeatureRefsComponent.vue'
import { Component, Prop } from 'vue-property-decorator'
import { $DBM, $APPNEXUS, $MEDIAMATH, $BEESWAX, $FACEBOOK, $THETRADEDESK, $META } from '../../../config/dspConfig'

const baseLocationInput = [
  { label: 'Project id', value: 'projectId' },
  { label: 'Shared bucket', value: 'bucketNameShared' },
  { label: 'Spillway bucket', value: 'bucketNameSpillway' }
]

/**
 * adaptation of settingsTabs for keystone V2
 */
@Component({
  components: {
    NewFeaturesComponent
  }
})
export default class SettingsTabsKeystoneV2 extends Vue {
  @Prop({ required: true }) memberSetting: MemberSettingModel
  @Prop({ required: true }) dspSettings: DSPSettingsModel
  @Prop({ required: true }) isLoading: Boolean
  @Prop({ required: true }) newFeatures: Array<NewFeatureReferenceModel>

  locationInput: { [key: string]: typeof baseLocationInput } = {
    [$DBM]: baseLocationInput,
    [$APPNEXUS]: baseLocationInput,
    [$BEESWAX]: baseLocationInput,
    [$FACEBOOK]: [ { label: 'Project id', value: 'projectId' } ],
    [$MEDIAMATH]: baseLocationInput,
    [$THETRADEDESK]: baseLocationInput,
    [$META]: [ { label: 'Project id', value: 'projectId' } ]
  }

  downloadSourceInput: { [key: string]: Array<any> } = {
    [$DBM]: [
      { label: 'Bucket list', value: 'bucketList', isList: true },
      { label: 'Dataset Import', value: 'datasetImport' },
      { label: 'Report LLD', value: 'reportLld' },
      { label: 'Report LLD conv', value: 'reportLldConv' },
      { label: 'Sdf version', value: 'sdfVersion' }
    ],
    [$APPNEXUS]: [
      { label: 'LLD bucket', value: 'bucketNameLld' },
      { label: 'Path Adserver conv', value: 'pathAdserverConversions', isList: true }
    ],
    [$BEESWAX]: [
      { label: 'S3 bucket', value: 'bucketS3' }
    ],
    [$FACEBOOK]: [
      { label: 'Business id', value: 'businessId' }
    ],
    [$MEDIAMATH]: [
      { label: 'Advertiser list_download', value: 'advertisersListDownload', isList: true }
    ],
    [$THETRADEDESK]: [
      { label: 'Report Template ID', value: 'reportTemplateId' }
    ]
  }
  dataFetchingSpecificitiesInput: { [key: string]: Array<{ [key: string]: any }> } = {
    [$DBM]: [
      { label: 'DL routine', value: 'dlRoutine' },
      { label: 'Advertiser list', value: 'advertisersList', isList: true },
      { label: 'Bucket Type', value: 'bucketType' },
      { label: 'Bucket Prefix', value: 'bucketPrefix' }
    ],
    [$APPNEXUS]: [
      { label: 'DL routine', value: 'dlRoutine' },
      { label: 'DL method', value: 'downloadMethod' },
      { label: 'Advertiser list', value: 'advertisersList', isList: true },
      { label: 'Is GDPR', value: 'isGdpr' },
      { label: 'Is Full Hashed', value: 'isFullHashed' }
    ],
    [$BEESWAX]: [
      { label: 'Prefix S3 bucket', value: 'bucketS3Prefix' },
      { label: 'DL routine', value: 'dlRoutine' },
      { label: 'Is Masquerade', value: 'masquerade' },
      { label: 'Advertiser list', value: 'advertisersList', isList: true }
    ],
    [$FACEBOOK]: [
      { label: 'Advertiser list', value: 'advertisersList', isList: true }
    ],
    [$MEDIAMATH]: [
      { label: 'DL routine', value: 'dlRoutine' }
    ],
    [$THETRADEDESK]: [
      { label: 'DL routine', value: 'dlRoutine' },
      { label: 'Streaming', value: 'streaming' },
      { label: 'Report Advertiser Filter', value: 'reportAdvertiserFilter' },
      { label: 'Advertisers list', value: 'advertisersList' }
    ]
  }
  brokerFeesId: Array<{ [key: string]: any }> = [
    { label: 'Broker Id', value: 'brokerId', isList: true },
    { label: 'Partner Fee Id', value: 'partnerFeeId' }
  ]

  showSnackbarCopyDone () {
    const barConfig = {
      type: 'success',
      message: 'Mail address successfully copied to clipboard!',
      color: 'green'
    }
    this.$store.commit('setSnackBarConfig', barConfig)
    this.$store.commit('setActiveSnackBar', true)
  }

  // GETTERS
  get displaySettings () {
    return this.dspSettings != null && this.memberSetting != null
  }
  get displayBrokerFees () {
    return this.displaySettings && this.dspSettings.dsp === $APPNEXUS
  }
  get downloadSourceInputCurrentDsp () {
    return this.displaySettings && (this.dspSettings.dsp in this.downloadSourceInput)
      ? this.downloadSourceInput[this.dspSettings.dsp]
      : []
  }
  get dataFetchingSpecificitiesInputCurrentDsp () {
    return this.displaySettings && (this.dspSettings.dsp in this.dataFetchingSpecificitiesInput)
      ? this.dataFetchingSpecificitiesInput[this.dspSettings.dsp]
      : []
  }
  get locationInputCurrentDsp () {
    return this.displaySettings && (this.dspSettings.dsp in this.locationInput)
      ? this.locationInput[this.dspSettings.dsp]
      : []
  }
  get isUserAllowedToChangeSurcoucheSettings () {
    return this.$store.getters.isUserDebugger
  }
  get currentDsp () {
    return this.memberSetting && this.memberSetting.member && this.memberSetting.member.dsp
      ? this.memberSetting.member.dsp
      : ''
  }

  get displaySurcoucheSettings () {
    return this.displaySettings && this.currentDsp !== '' && [$APPNEXUS].indexOf(this.currentDsp) !== -1
  }
}
